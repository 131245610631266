<template>
  <div class="page-core-tech">
    <div class="title-wrapper ">
      <img src="../../../public/static/img/mobile/baner_03.png" alt="">
      <div class="desc">
        <h2>基于人工智能 </h2>
        <h2>运用大数据、云计算</h2>
        <h2>实现IPA流程自动化</h2>
      </div>
     
    </div>
    <div class="core-tech-detail">
      <div class="medium-item">
        <h4 class="title">人工智能AI核心技术的引入</h4>
        <img src="static/img/mobile/media_item_01.png " alt="">
        <p class="text">机器人自动回复，智能快捷回答，完美实现人工和机器人的对接，数据统计平台支撑</p>
      </div>
      <div class="medium-item">
        <h4 class="title">IPA全流程自动化</h4>
        <img src="static/img/mobile/media_item_02.png " alt="">
        <p class="text">前端用户操作体验强，自动对接船公司API，无需再次确认，避免重复劳动</p>
      </div>
       <div class="medium-item">
        <h4 class="title">云计算技术支撑数据核算</h4>
        <img src="static/img/mobile/media_item_03.png " alt="">
        <p class="text">分布式计算在极短时间内完成数以万计的数据处理，提升系统性能体验</p>
      </div>
       <div class="medium-item">
        <h4 class="title">大数据技术支撑数据核算</h4>
        <img src="static/img/mobile/media_item_04.png " alt="">
        <p class="text">将公司数据构成大数据池，通过算法技术显示最新航线仓位价格</p>
      </div>
    </div>
    <div class="bg-line"></div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../style/mixin.scss";
.page-core-tech {
  margin-bottom: 30px;
  .title-wrapper {
    position: relative;
    img {
      width: 100%;
    }
    .desc {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
    h2 {
      font-size: 28px;
      line-height: 40px;
      color: $themeColor;
      text-align: center;
    }
  } 
  .core-tech-detail {
    margin-bottom: 10px;
    .medium-item {
      position: relative;
      padding: 30px 0;
       &:nth-of-type(odd) {
         background: #fff8f3;
       }
       .title {
          font-size: 28px;
          font-weight: bold;
          color: $themeColor;
          margin-bottom: 24px; 
          text-align: center;
          padding: 0 30px;
       }
       img {
         width: 160px;
         display: block;
         margin: 30px auto ;
       }
       .text {
         color: #823c0d;
         font-size: 18px;
         text-align: center;
         padding: 0 30px;
         line-height: 26px;
       }
    }
  }
  .bg-line {
    border: 1px solid #e1e7ff;
    margin: 0px 20px 30px;
  }
}
</style>

<script>
import CommonBanner from "../../components/CommonBanner";

export default {
  components: {
    CommonBanner
  }
}
</script>